import { useEffect, useState } from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import {
  BrowserRouter,
  Path as NavigatePath,
  NavigateOptions,
} from 'react-router-dom';
import AppProtectedRoutes from '../routes/auth-routes';
import platform from '../../../infra/platform';
import Loader from '../../../shared/components/molecules/loader';
import { MUI_LICENCE_KEY, getLoadingText } from '../../../shared/constants';
import theme from '../../../theme';
import usePolyfills from '../../../shared/polyfills/hooks';
import QSBox from '../../../shared/components/atoms/box';
import QSTypography from '../../../shared/components/atoms/typography';
import { Button, Slide, SlideProps, Snackbar } from '@mui/material';
import { getFirebaseTokenFromPlatform } from '../../../infra/auth';
import { TeamMemberContact } from '../../team-management/v1/typings';
import {
  BotFlowData,
  TemplateSendData,
} from '../../bots/v1/typings';
import useQuery from '../../../shared/hooks/useQuery';
import { UserProfileData } from '../../user-profile/v1/typings';
import userProfileAtom from '../../user-profile/v1/state';
import { getUserDetails } from '../../user-profile/v1/requests';
import { getI18N } from '../../../i18N';
import { ChannelsMemberAdd } from '../../channel-management/v1/typings';
import useScrollbar from '../../../shared/hooks/use-scrollbar';
import { Template } from '../../../shared/typings/template';
import { FBInitConfig, FBLoginOptions, FBLoginResponse, WabaIntegrationMemberStatus } from '../../waba-channels/v1/typings';
import { ChargebeeInitParams, ChargebeeInstance } from '../../plans/typings';

LicenseInfo.setLicenseKey(MUI_LICENCE_KEY);

interface CustomEventMap {
  reloadpagedata: CustomEvent<{ refreshType: string }>;
  teamContactAdded: CustomEvent<TeamMemberContact>;
  templateDataSend: CustomEvent<TemplateSendData>;
  refreshCustomers: CustomEvent<{
    customerName: string;
    customerId: string;
  }>;
  successEvent: CustomEvent<{
    successMessage: string;
  }>;
  addMemberToChannel: CustomEvent<ChannelsMemberAdd>;
  deleteChannel: CustomEvent<{
    id: string;
  }>;
  prefillTemplate: CustomEvent<Template>;
  getCurlForSendingTemplateViaApi: CustomEvent;
  getSelectedCustomerIds: CustomEvent<{
    selectedCustomerIds: string[];
  }>;
  editBot: CustomEvent<Partial<BotFlowData>>;
  addBot: CustomEvent<BotFlowData>;
  changeCustomToken: CustomEvent<{ token: string }>;
  setRoutePath: CustomEvent<{
    path: Partial<NavigatePath>;
    options?: NavigateOptions;
  }>;
  setUserStatus: CustomEvent<{
    status: WabaIntegrationMemberStatus | null;
    statusExpiration: string | null;
  }>;
}

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

declare global {
  interface HTMLElement {
    mozRequestFullScreen: (options?: FullscreenOptions) => void;
    webkitRequestFullScreen: (options?: FullscreenOptions) => void;
  }

  interface Document {
    // Adds definition to Document
    addEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void
    ): void;
    removeEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void
    ): void;
    mozFullScreenEnabled: boolean;
    mozFullScreenElement: boolean;
    webkitFullscreenElement: boolean;
    webkitCurrentFullScreenElement: boolean;
    mozCancelFullScreen: () => Promise<void>;
    webkitCancelFullScreen: () => Promise<void>;
    webkitExitFullscreen: () => Promise<void>;
  }

  interface Window {
    Razorpay: any;
  }

  interface Window {
    Chargebee: {
      init: (params: ChargebeeInitParams) => void;
      getInstance: () => ChargebeeInstance;
    };
  }

  interface Window {
    fbq?: (
      action: string,
      eventName: string,
      params: Record<string, string>
    ) => void;
    FB?: {
      init?: (config: FBInitConfig) => void;
      login?: (
        callback: (response: FBLoginResponse) => void,
        options: FBLoginOptions
      ) => void;
    };
    fbAsyncInit?: () => void;
  }
}

function App({
  updateServiceWorker,
}: {
  readonly updateServiceWorker: (
    registration: ServiceWorkerRegistration
  ) => void;
}) {
  const [isAuthReady, setIsAuthReady] = useState(false);
  const polyfillState = usePolyfills();
  const [isSnackOpen, setSnackOpen] = useState<boolean>(false);
  const { t } = getI18N();

  const { GlobalScrollbar } = useScrollbar();

  const handleUpdateServiceWorker = ($event: Event) => {
    setSnackOpen(true);
    const $customEvent = $event as CustomEvent<{
      registration: ServiceWorkerRegistration;
    }>;
    if ($customEvent?.detail?.registration) {
      updateServiceWorker($customEvent.detail.registration);
    }
  };

  const handleChangeCustomToken = () => {
    fetchToken();
  };

  const {
    requestData: {
      loading: userDataLoading,
      error: userDataError,
      data: userData,
    },
    fetchData: fetchUser,
  } = useQuery<UserProfileData>({
    requestAtom: userProfileAtom,
    queryFunction: getUserDetails,
    autoFetch: false,
  });

  const fetchToken = () => {
    getFirebaseTokenFromPlatform().then(() => {
      setIsAuthReady(true);
      fetchUser();
    });
  };

  useEffect(() => {
    fetchToken();
  }, [fetchUser]);

  useEffect(() => {
    platform.setStatusBarColor();
    platform.setNavigationBarColor();
    platform.getOrigin();
  }, []);

  useEffect(() => {
    document.addEventListener('updateServiceWorker', handleUpdateServiceWorker);
    document.addEventListener('changeCustomToken', handleChangeCustomToken);
    return () => {
      document.removeEventListener(
        'updateServiceWorker',
        handleUpdateServiceWorker
      );
      document.removeEventListener(
        'changeCustomToken',
        handleChangeCustomToken
      );
    };
  }, []);

  if (polyfillState.loading || userDataLoading) {
    return <Loader size={32} secondary={getLoadingText()} />;
  }

  if (polyfillState.error || userDataError) {
    return (
      <QSBox
        sx={{
          width: '100%',
          height: '100%',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <QSTypography color={theme.palette.error.main}>
          {polyfillState.error ?? userDataError?.message}
        </QSTypography>
      </QSBox>
    );
  }

  function renderAuthRoutes() {
    if (isAuthReady && userData) {
      return <AppProtectedRoutes />;
    }

    return <Loader size={32} secondary={getLoadingText()} />;
  }

  return (
    <>
      <Snackbar
        open={isSnackOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        message={t('app_requires_a_reload')}
        TransitionComponent={TransitionUp}
        action={
          <Button
            size="small"
            onClick={() => {
              window.location.reload();
            }}
          >
            {t('reload')}
          </Button>
        }
      />
      <GlobalScrollbar />
      <BrowserRouter>{renderAuthRoutes()}</BrowserRouter>
    </>
  );
}

export default App;
