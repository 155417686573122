// get class names as a string

const clsx = (...args: any[]) => {
  const classes: string[] = [];
  args.forEach((arg) => {
    if (arg) {
      if (typeof arg === 'string') {
        classes.push(arg);
      } else if (typeof arg === 'object') {
        Object.entries(arg).forEach(([key, value]) => {
          if (arg[key] && value) {
            classes.push(key);
          }
        });
      }
    }
  });

  return classes.join(' ');
};

export default clsx;
