import { AlertColor } from '@mui/material';
import { TemplateSendData } from '../../modules/bots/v1/typings';
import { WabaIntegrationMemberStatus } from '../../modules/waba-channels/v1/typings';

export type Extend<T, K extends string, V> = T & { [P in K]: V };

export interface SnackState {
  open: boolean;
  message: string;
  type: AlertColor;
}

export const TEXT_COLOR = '#5F5F85';

export const TEXT_COLOR_LIGHT = '#8484A8';

export const CHAT_BUBBLE_BORDER_COLOR = '#F4F4F9';

export const CHAT_BUBBLE_SHADOW_COLOR = '#D2D2D2';

export const CHAT_BUBBLE_FOOTER_COLOR = '#8A8A9D';

export const VARIABLES_BORDER_COLOR = 'rgba(190, 190, 213, 1)';

export const APP_BACKGROUD_COLOR = '#FBFBFC';

export const PRIMARY_BACKGROUND_COLOR = '#FFFFFF';

export const TABS_INDICATOR_COLOR = '#D7E0E7';

export const TABS_BORDER_COLOR = '#D7E0E7';

export const BLACK_COLOR = '#2d2d2d';

export const HEADER_COLOR = '#FFFFFF';

export const ERROR_BORDER = '#EF5350';

export const BORDER_COLOR = '#E0E0EA';

export const BORDER_RADIUS = '4px';

export const PAGE_PADDING = '16px';

export const GROUPS_ICON_COLOR = '#C1D6D0';

export const CHAT_BUBBLE_CUST_BG_COLOR = '#FFFFFF';

export const CHAT_BUBBLE_USER_BG_COLOR = 'rgb(214, 241, 225)';

export const GREEN = '#29A471';

export const GREEN_HOVER = 'rgb(44, 100, 76)';

export const ERROR_COLOR = '#FF1744';

export const RED = '#EF5350';

export const DARK_GREY = '#757575';

export const WARNING_COLOR = '#FFDD57';

export interface TemplateChat {
  bodyText: string;
  buttons: { [key: string]: string }[];
  footerText: string;
  headerText: string;
}

export interface MessageData {
  identifiersMap: { [key: string]: string };
  templateChat: TemplateChat;
  templateId: string;
}

export interface TemplateMessageData {
  messageData: TemplateSendData;
  messageType: string;
}

export interface Country {
  countryCode: string;
  label: string;
  symbol: string;
}

export interface Geo {
  geo: {
    city: string;
    country: string;
    countryCode: string;
    currency: string;
    region: string;
    regionName: string;
    status: string;
    timezone: string;
    zip: string;
    currencyCode: string;
  };
}

export type PhoneDropdownPosition = 'top' | 'bottom';

export interface CurlOptions {
  method: string;
  url: string;
  headers?: { [key: string]: string };
  data?: object;
}

export interface CurrentPath {
  href: string;
  pathname: string;
  search: string;
  hash: string;
  host: string;
  protocol: string;
}

export interface AgentStatusSocket {
  userId: string;
  teamId: string;
  memberId: string;
  status: WabaIntegrationMemberStatus | null;
  statusExpiration: string | null;
}
