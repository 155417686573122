/** @jsxImportSource @emotion/react */
import { Global, css } from '@emotion/react';

const useScrollbar = () => {
  const scrollbarStyle = css`
    /* total width */
    ::-webkit-scrollbar {
      background-color: rgba(0, 0, 0, 0);
      width: 8px;
      height: 8px;
      padding: 4px;
      overflow: overlay;
      z-index: 999999;
    }

    /* background of the scrollbar except button or resizer */
    ::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
    }

    /* scrollbar itself */
    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0);
      border-radius: 16px;
      border: 0px solid #fff;
    }

    /* set button(top and bottom of the scrollbar) */
    ::-webkit-scrollbar-button {
      display: none;
    }

    /* scrollbar when element is hovered */
    :hover::-webkit-scrollbar-thumb {
      background-color: #a0a0a5;
    }

    /* scrollbar when scrollbar is hovered */
    ::-webkit-scrollbar-thumb:hover {
      background-color: #a0a0a5;
    }
  `;

  const GlobalScrollbar = () => {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const isIphone = navigator.platform.toUpperCase().indexOf('IPHONE') >= 0;
    const isIpad = navigator.platform.toUpperCase().indexOf('IPAD') >= 0;
    if (isMac || isIphone || isIpad) return null;
    return <Global styles={scrollbarStyle} />;
  };

  return {
    GlobalScrollbar,
  };
};

export default useScrollbar;
