/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Route, Routes } from 'react-router-dom';
import getRoutesV1 from './v1';
import { getI18N } from '../../../../i18N';
import QSTypography from '../../../../shared/components/atoms/typography';

export default function AppProtectedRoutes() {
  const { t } = getI18N();
  return (
    <Routes>
      {getRoutesV1()}
      <Route path="*" element={(
        <div
          css={css`
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 16px;
          `}
        >
          <QSTypography
            css={css`
              font-size: 16px;
              font-weight: 400;
            `}
          >
            {t('nothing_to_see_here')}
          </QSTypography>
        </div>
      )}
    />
    </Routes>
  );
}
