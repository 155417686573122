/** @jsxImportSource @emotion/react */
import { useAtom, useAtomValue } from 'jotai';
import { ButtonProps, IconButton, SxProps, Theme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import FilterIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { FC, ReactNode, createContext, CSSProperties, useContext } from 'react';
import platform from '../../../../infra/platform';
import { css } from '@emotion/react';
import QSButton from '../../atoms/button';
import QSIconButton from '../../atoms/icon-button';
import { HEADER_COLOR } from '../../../typings';
import QSTypography from '../../atoms/typography';
import QSBox, { QSBoxProps } from '../../atoms/box';
import theme from '../../../../theme';
import { getI18N } from '../../../../i18N';
import { QSCheckbox } from '../../atoms/form';
import Loader from '../../molecules/loader';
import MenuDropdown, { MenuItemI } from '../menu-dropdown';
import { toggleSelectedBroadcastIdsAtom } from '../../../../modules/channel-management/v1/state/manage-channel';
import { HEADER_HEIGHT } from '../../../../modules/commerce/v2/typings';

const HeaderContext = createContext(true);

interface HeaderProps {
  children: ReactNode | ReactNode[];
  boxProps?: Omit<QSBoxProps, 'children'>;
  useDefaultStyles?: boolean;
}

const Header: FC<HeaderProps> = ({
  children,
  boxProps,
  useDefaultStyles = true,
}) => {
  const toggleSelectedBroadcastIds = useAtomValue(
    toggleSelectedBroadcastIdsAtom
  );

  const styles: SxProps<Theme> = useDefaultStyles
    ? {
        padding: toggleSelectedBroadcastIds
          ? '16px 12px'
          : '16px 12px 16px 16px',
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        height: `${HEADER_HEIGHT}`,
        borderBottom: '1px solid rgba(224, 224, 234, 1)',
        background: HEADER_COLOR,
        width: '100%',
      }
    : {};

  return (
    <HeaderContext.Provider value={true}>
      <QSBox {...boxProps} sx={{ ...styles, ...boxProps?.sx }}>
        {children ? children : null}
      </QSBox>
    </HeaderContext.Provider>
  );
};

interface HeaderPrimaryButtonProps {
  children?: JSX.Element;
  boxProps?: Omit<QSBoxProps, 'children'>;
  onClick?: () => void;
  showBack?: boolean;
  showMenu?: boolean;
}

const HeaderPrimaryButton: FC<HeaderPrimaryButtonProps> = ({
  children,
  boxProps,
  onClick,
  showBack,
  showMenu,
}) => {
  const { t } = getI18N();
  const context = useContext(HeaderContext);
  const headerPrimaryButtonStyles = css`
    margin-right: 12px;
  `;

  if (!context) {
    throw new Error(t('header_children_error'));
  }

  if (showBack) {
    return (
      <QSBox {...boxProps} onClick={onClick}>
        <QSIconButton
          aria-label="go back"
          color="inherit"
          sx={{
            backgroundColor: 'transparent',
          }}
          css={headerPrimaryButtonStyles}
        >
          <ArrowBackRoundedIcon />
        </QSIconButton>
      </QSBox>
    );
  }

  if (showMenu) {
    return (
      <QSBox
        {...boxProps}
        onClick={platform.openNavigationDrawer}
        css={headerPrimaryButtonStyles}
      >
        <QSIconButton
          sx={{
            borderRadius: '3px',
            border: '1px solid rgba(224, 224, 234, 1)',
            boxShadow: '0px 1px 1px rgba(210, 210, 210, 0.5)',
            backgroundColor: 'rgba(255, 255, 255, 1)',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            css={css({
              height: '16px',
              width: '16px',
            })}
          >
            <line x1="3" y1="12" x2="21" y2="12" />
            <line x1="3" y1="6" x2="21" y2="6" />
            <line x1="3" y1="18" x2="21" y2="18" />
          </svg>
        </QSIconButton>
      </QSBox>
    );
  }

  if (children) {
    return (
      <QSBox {...boxProps} onClick={onClick}>
        {children}
      </QSBox>
    );
  }

  return null;
};

interface HeaderContentProps {
  children?: JSX.Element;
  style?: CSSProperties;
  titleStyle?: CSSProperties;
  boxProps?: Omit<QSBoxProps, 'children'>;
  title?: string | JSX.Element;
  renderTitle?: () => JSX.Element;
}

const HeaderContent: FC<HeaderContentProps> = ({
  title,
  renderTitle,
  children,
  boxProps,
  style,
  titleStyle,
}) => {
  const context = useContext(HeaderContext);
  const { t } = getI18N();
  const headerContentStyles = css`
    margin-right: 12px;
  `;

  if (!context) {
    throw new Error(t('header_children_error'));
  }

  if (title) {
    return (
      <QSBox
        css={css`
          overflow: hidden;
          flex: 1 1;
          ${headerContentStyles}
        `}
        {...boxProps}
      >
        <QSTypography
          css={css`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 700;
            font-size: 16px;
          `}
          style={titleStyle}
        >
          {title}
        </QSTypography>
      </QSBox>
    );
  }

  if (typeof renderTitle === 'function') {
    return renderTitle();
  }

  return (
    <QSBox {...boxProps} css={headerContentStyles}>
      {children ? children : null}
    </QSBox>
  );
};

interface HeaderSecondaryButtonProps {
  children?: ReactNode | ReactNode[];
  boxProps?: Omit<QSBoxProps, 'children'>;
  showThreeDots?: boolean;
  onClick?: () => void;
  showFilter?: boolean;
  filtersCount?: number;
  onClickFilter?: () => void;
  toggleCheckBox?: () => void;
  isChecked?: () => boolean;
  useDefaultStyles?: boolean;
  useButton?: boolean;
  useMoreVertButton?: boolean;
  getMenuItems?: () => null | MenuItemI[];
  onSelectMenuItem?: (menuItem: MenuItemI) => void;
  moreVertButtonProps?: ButtonProps;
  buttonProps?: ButtonProps;
  toggleCheckIcon?: boolean;
  showSearch?: boolean;
  showCheckBox?: boolean;
  loadingCheckbox?: boolean;
  searchButtonCSS?: CSSProperties;
}

const HeaderSecondaryButton: FC<HeaderSecondaryButtonProps> = ({
  children,
  boxProps,
  showThreeDots,
  onClick,
  showSearch,
  toggleCheckIcon,
  showFilter = false,
  filtersCount,
  onClickFilter = () => {},
  toggleCheckBox = () => {},
  isChecked = () => false,
  useDefaultStyles = true,
  useButton = true,
  useMoreVertButton,
  getMenuItems,
  onSelectMenuItem,
  moreVertButtonProps,
  buttonProps,
  showCheckBox = false,
  loadingCheckbox = false,
  searchButtonCSS = {},
}) => {
  const { t } = getI18N();
  const context = useContext(HeaderContext);

  const [toggleSelectedBroadcastIds, setToggleSelectedBroadcastIds] = useAtom(
    toggleSelectedBroadcastIdsAtom
  );

  if (!context) {
    throw new Error(t('header_children_error'));
  }

  const styles = useDefaultStyles
    ? {
        ml: 'auto',
      }
    : {};

  const components = [];

  if (showSearch) {
    components.push(
      <QSBox key="channel-list-search" sx={searchButtonCSS} onClick={onClick}>
        <IconButton aria-label="search" color="inherit">
          <SearchIcon />
        </IconButton>
      </QSBox>
    );
  }

  if (toggleCheckIcon && !toggleSelectedBroadcastIds) {
    components.push(
      <QSBox
        key="channel-list-select"
        sx={searchButtonCSS}
        onClick={() => setToggleSelectedBroadcastIds(true)}
      >
        <IconButton
          css={css`
            color: #2d2d2d;
            padding: 4px;
          `}
        >
          <CheckIcon />
        </IconButton>
      </QSBox>
    );
  }

  const renderButton = () => {
    if (!useButton) {
      return null;
    }

    return (
      <QSBox
        {...boxProps}
        sx={{ ...styles, ...boxProps?.sx }}
        onClick={onClick}
      >
        <QSButton {...buttonProps}>{children ? children : null}</QSButton>
      </QSBox>
    );
  };

  if (useMoreVertButton) {
    return (
      <>
        {renderButton()}
        {components}
        <MenuDropdown
          show={useMoreVertButton}
          getMenuItems={getMenuItems}
          onSelectMenuItem={onSelectMenuItem}
          moreVertButtonProps={moreVertButtonProps}
        />
      </>
    );
  }

  if (showThreeDots) {
    return (
      <QSBox
        {...boxProps}
        sx={{ ...styles, ...boxProps?.sx }}
        onClick={onClick}
      >
        <QSIconButton
          sx={{
            background: 'none',
            border: 'none',
            boxShadow: 'none',
            ml: 'auto',
            padding: '0',
            // mr: '10px',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#2d2d2d"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            css={css({
              transform: 'rotate(90deg)',
            })}
          >
            <circle cx="12" cy="12" r="1" />
            <circle cx="19" cy="12" r="1" />
            <circle cx="5" cy="12" r="1" />
          </svg>
        </QSIconButton>
      </QSBox>
    );
  }

  if (showSearch || showFilter || showCheckBox) {
    return (
      <QSBox
        {...boxProps}
        sx={{ ...styles, ...boxProps?.sx }}
        css={css({
          display: 'flex',
          flex: '0 0 auto',
        })}
      >
        {showFilter && (
          <QSBox onClick={onClickFilter}>
            <IconButton
              sx={{ position: 'relative' }}
              aria-label="filter"
              color="inherit"
            >
              <FilterIcon />
              {filtersCount ? (
                <span
                  css={css`
                    position: absolute;
                    top: 1px;
                    right: 1px;
                    font-size: ${5 +
                    10 / (filtersCount?.toString?.()?.length ?? 1)}px;
                    text-align: center;
                    background: ${theme.palette.primary.main};
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    line-height: 20px;
                    color: #fff;
                  `}
                >
                  {filtersCount}
                </span>
              ) : null}
            </IconButton>
          </QSBox>
        )}
        {showSearch && (
          <QSBox sx={searchButtonCSS} onClick={onClick}>
            <IconButton aria-label="search" color="inherit">
              <SearchIcon />
            </IconButton>
          </QSBox>
        )}
        {showCheckBox &&
          (loadingCheckbox ? (
            <div
              css={css`
                padding-right: 12px;
              `}
            >
              <Loader size={20} />
            </div>
          ) : (
            <QSCheckbox
              onClick={toggleCheckBox}
              checked={isChecked()}
              size="small"
              sx={{
                color: 'rgb(0,0,0)',
              }}
            />
          ))}
      </QSBox>
    );
  }

  if (useButton) {
    return renderButton();
  }

  return <QSBox {...boxProps}>{children ? children : null}</QSBox>;
};

export { Header, HeaderContent, HeaderPrimaryButton, HeaderSecondaryButton };
