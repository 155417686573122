import { Path as NavigatePath, NavigateOptions } from 'react-router-dom';
import { openPopup } from '../../shared/utils/popup';
import { CurrentPath } from '../../shared/typings';
import { BotFlowData } from '../../modules/bots/v1/typings';
import { ChannelsMemberAdd, FrequencyCappingState } from '../../modules/channel-management/v1/typings';
import {
  ShareCommerce,
  ShareBroadcastCommerce,
} from '../../modules/commerce/v2/typings';
import { CustomFieldsTypes } from '../../modules/custom-fields/v1/typings';
import { OpenCustomerChatData } from '../../modules/customers/v1/typings';
import {
  AnalyticsEventNames,
  AnalyticsEventOptions,
} from '../../shared/typings/analytics';
import { Template } from '../../shared/typings/template';
import BasePlatform from './base';
import { WabaIntegrationMemberStatus } from '../../modules/waba-channels/v1/typings';
import { setUserChannelData } from '../../shared/utils';

enum WebMessageTypes {
  LOGIN = 'LOGIN',
  SELECT_TEMPLATE = 'templateDataSend',
  ADD_MEMBER_TO_CHANNEL = 'ADD_MEMBER_TO_CHANNEL',
  DELETE_CHANNEL = 'DELETE_CHANNEL',
  PREFILL_TEMPLATE = 'PREFILL_TEMPLATE',
  GET_SELECTED_CUSTOMER_IDS = 'GET_SELECTED_CUSTOMER_IDS',
  GET_CURL_FOR_SENDING_TEMPLATE_VIA_API = 'GET_CURL_FOR_SENDING_TEMPLATE_VIA_API',
  EDIT_BOT = 'EDIT_BOT',
  CHANGE_CUSTOM_TOKEN = 'CHANGE_CUSTOM_TOKEN',
  ROUTE_PATH = 'ROUTE_PATH',
  GET_CURRENT_PATH = 'GET_CURRENT_PATH',
  SET_USER_STATUS = 'SET_USER_STATUS',
  ADD_BOT = 'ADD_BOT',
  CHANGE_CHANNEL_PARTNER = 'CHANGE_CHANNEL_PARTNER',
  UPDATE_AUTO_RETRY = 'UPDATE_AUTO_RETRY',
  READ_ORIGIN = 'READ_ORIGIN',
}

type WebLoginResult = {
  type: WebMessageTypes.LOGIN;
  data: {
    token: string;
  };
};

type WebOriginResults = {
  type: WebMessageTypes.READ_ORIGIN;
  data: {
    origin: string;
  };
};

type WebSelectTemplateResult = {
  type: WebMessageTypes.SELECT_TEMPLATE;
  data: {
    id: string | number;
    variables: { type: string; text: string }[];
  };
};

type WebDeleteChannelResult = {
  type: WebMessageTypes.DELETE_CHANNEL;
  data: {
    id: string;
  };
};

type WebAddMemberToChannelResult = {
  type: WebMessageTypes.ADD_MEMBER_TO_CHANNEL;
  data: ChannelsMemberAdd;
};

type WebPrefillTemplateResult = {
  type: WebMessageTypes.PREFILL_TEMPLATE;
  data: Template;
};

type WebGetCurlResult = {
  type: WebMessageTypes.GET_CURL_FOR_SENDING_TEMPLATE_VIA_API;
  data: {};
};

type WebGetSelectedCustomerIds = {
  type: WebMessageTypes.GET_SELECTED_CUSTOMER_IDS;
  data: {
    selectedCustomerIds: string[];
  };
};

type WebEditBot = {
  type: WebMessageTypes.EDIT_BOT;
  data: Partial<BotFlowData>;
};

type WebAddBot = {
  type: WebMessageTypes.ADD_BOT;
  data: BotFlowData;
};

type WebChangeCustomToken = {
  type: WebMessageTypes.CHANGE_CUSTOM_TOKEN;
  data: {
    token: string;
  };
};
export interface ChannelPartnerDetails {
  id: string;
  name: string;
  supportNumber: string;
  ownerId: string;
  logoUrl: string;
  domain: string;
  isOwner: boolean;
}

type WebChangeChannelPartnerConfig = {
  type: WebMessageTypes.CHANGE_CHANNEL_PARTNER;
  data: ChannelPartnerDetails;
};

type WebRoutePath = {
  type: WebMessageTypes.ROUTE_PATH;
  data: {
    path: Partial<NavigatePath>;
    options?: NavigateOptions;
  };
};

type WebUpdateAutoRetry = {
  type: WebMessageTypes.UPDATE_AUTO_RETRY;
  data: {
    state: FrequencyCappingState;
    nextScheduledAt?: string;
  };
};

type WebCurrentPath = {
  type: WebMessageTypes.GET_CURRENT_PATH;
  data: CurrentPath;
};

type SetUserStatus = {
  type: WebMessageTypes.SET_USER_STATUS;
  data: {
    status: WabaIntegrationMemberStatus | null;
    statusExpiration: string | null;
  };
};

type WebResult =
  | WebLoginResult
  | WebSelectTemplateResult
  | WebAddMemberToChannelResult
  | WebDeleteChannelResult
  | WebPrefillTemplateResult
  | WebGetSelectedCustomerIds
  | WebGetCurlResult
  | WebEditBot
  | WebChangeCustomToken
  | WebChangeChannelPartnerConfig
  | WebRoutePath
  | WebCurrentPath
  | SetUserStatus
  | WebAddBot
  | WebOriginResults
  | WebUpdateAutoRetry;

type WebRequestPromiseCache = {
  login?: {
    cachedPromiseResolve: (value: string | PromiseLike<string>) => void;
  };
  currentPath?: {
    cachedPromiseResolve: (
      value: CurrentPath | PromiseLike<CurrentPath>
    ) => void;
  };
};

export default class Web extends BasePlatform {
  cachedPromise: WebRequestPromiseCache;

  constructor() {
    super();
    this.cachedPromise = {};
  }

  isCurrentPlatform(): boolean {
    const currentPlatform = typeof window !== 'undefined';
    if (currentPlatform) {
      this.listenForMessageFromWeb();
    }
    return currentPlatform;
  }
  
  getUtmSource(): string {
    return 'web'
  }

  private listenForMessageFromWeb() {
    window.onmessage = ({ data }: { data: any }) => {
      if (typeof data !== 'string') {
        return;
      }
      try {
        this.handleMessageFromWeb(JSON.parse(data) as unknown as WebResult);
      } catch (error) {
        // Do nothing
      }
    };
  }

  private handleMessageFromWeb({ type, data }: WebResult) {
    switch (type) {
      case WebMessageTypes.LOGIN: {
        this.cachedPromise.login?.cachedPromiseResolve?.(data.token);
        delete this.cachedPromise.login;
        break;
      }
      case WebMessageTypes.READ_ORIGIN: {
        localStorage?.setItem('parent-origin', data.origin);
        break;
      }
      case WebMessageTypes.SELECT_TEMPLATE: {
        const customEvent = new CustomEvent('templateDataSend', {
          detail: data,
        });
        document.dispatchEvent(customEvent);
        break;
      }
      case WebMessageTypes.ADD_MEMBER_TO_CHANNEL: {
        const customEvent = new CustomEvent('addMemberToChannel', {
          detail: data,
        });
        document.dispatchEvent(customEvent);
        break;
      }
      case WebMessageTypes.DELETE_CHANNEL: {
        const customEvent = new CustomEvent('deleteChannel', {
          detail: data,
        });
        document.dispatchEvent(customEvent);
        break;
      }
      case WebMessageTypes.PREFILL_TEMPLATE: {
        const customEvent = new CustomEvent('prefillTemplate', {
          detail: data,
        });
        document.dispatchEvent(customEvent);
        break;
      }
      case WebMessageTypes.GET_CURL_FOR_SENDING_TEMPLATE_VIA_API: {
        const customEvent = new CustomEvent('getCurlForSendingTemplateViaApi', {
          detail: data,
        });
        document.dispatchEvent(customEvent);
        break;
      }
      case WebMessageTypes.GET_SELECTED_CUSTOMER_IDS: {
        const customEvent = new CustomEvent('getSelectedCustomerIds', {
          detail: data,
        });
        document.dispatchEvent(customEvent);
        break;
      }
      case WebMessageTypes.EDIT_BOT: {
        const customEvent = new CustomEvent('editBot', {
          detail: data,
        });
        document.dispatchEvent(customEvent);
        break;
      }
      case WebMessageTypes.ADD_BOT: {
        const customEvent = new CustomEvent('addBot', {
          detail: data,
        });
        document.dispatchEvent(customEvent);
        break;
      }
      case WebMessageTypes.CHANGE_CUSTOM_TOKEN: {
        const customEvent = new CustomEvent('changeCustomToken', {
          detail: data,
        });
        document.dispatchEvent(customEvent);
        break;
      }
      case WebMessageTypes.CHANGE_CHANNEL_PARTNER: {
        setUserChannelData(data);
        break;
      }
      case WebMessageTypes.ROUTE_PATH: {
        const customEvent = new CustomEvent('setRoutePath', {
          detail: data,
        });
        document.dispatchEvent(customEvent);
        break;
      }
      case WebMessageTypes.UPDATE_AUTO_RETRY: {
        const customEvent = new CustomEvent('updateAutoRetry', {
          detail: data,
        });
        document.dispatchEvent(customEvent);
        break;
      }
      case WebMessageTypes.GET_CURRENT_PATH: {
        this.cachedPromise.currentPath?.cachedPromiseResolve?.(data);
        delete this.cachedPromise.currentPath;
        break;
      }
      case WebMessageTypes.SET_USER_STATUS: {
        const customEvent = new CustomEvent('setUserStatus', {
          detail: data,
        });
        document.dispatchEvent(customEvent);
        break;
      }
      default: {
        break;
      }
    }
  }

  async getToken(): Promise<string> {
    return new Promise((resolve) => {
      this.cachedPromise.login = {
        cachedPromiseResolve: resolve,
      };

      window.parent.postMessage({ type: 'ASK_TOKEN' }, '*', []);
    });
  }

  getOrigin() {
    window?.parent?.postMessage?.({ type: 'ASK_ORIGIN' }, '*', []);
  }

  getVersion(): number {
    return 1;
  }

  goBack(): void {
    window.parent.postMessage({ type: 'GO_BACK' }, '*', []);
  }

  openRenewalBlocker(): void {
    window.parent.postMessage({ type: 'OPEN_RENEWAL_BLOCKER' }, '*', []);
  }
  
  logout(): void {
    window.parent.postMessage({ type: 'LOGOUT' }, '*', []);
  }

  finishActivity(): void {
    window.parent.postMessage({ type: 'CLOSE_IFRAME' }, '*', []);
  }

  openIntegrations(): void {
    window.parent.postMessage({ type: 'OPEN_INTEGRATIONS' }, '*', []);
  }
  uploadCustomersFromPhonebook(): void {
    // throw new Error('Not implemented');
  }

  setNavigationBarColor(): void {
    // throw new Error('Not implemented');
  }

  setStatusBarColor(): void {
    // throw new Error('Not implemented');
  }

  openPricingPlans(): void {
    // throw new Error('Not implemented');
  }

  openNavigationDrawer(): void {
    // throw new Error('Not implemented');
  }

  addGroupMembers(
    groupId: string,
    groupChatId: string,
    groupChatName: string
  ): void {
    window.parent.postMessage(
      {
        type: 'ADD_GROUP_MEMBERS',
        data: {
          groupChatId,
          groupId,
          groupChatName,
        },
      },
      '*',
      []
    );
  }

  messageGroupMember({
    customerId,
    customerName,
    chatId,
    phoneNumber,
    integrationId,
    integrationWabaNumber,
  }: {
    customerId: string;
    customerName: string;
    chatId?: string;
    phoneNumber?: string;
    integrationId?: string;
    integrationWabaNumber?: string;
  }): void {
    window.parent.postMessage(
      {
        type: 'SEND_INDIVIDUAL_MESSAGE',
        data: {
          customerId,
          customerName,
          chatId,
          phoneNumber,
          integrationId,
          integrationWabaNumber,
        },
      },
      '*',
      []
    );
  }

  openGroupChat(
    groupChatId: string,
    groupId: string,
    groupChatName: string,
    groupAccessLevel: string,
    canManageGroupAccess: boolean
  ): void {
    window.parent.postMessage(
      {
        type: 'OPEN_GROUP_CHAT',
        data: {
          groupChatId,
          groupId,
          groupChatName,
          groupAccessLevel,
          canManageGroupAccess,
        },
      },
      '*',
      []
    );
  }

  shareCommerce(data: ShareCommerce): void {}

  shareBroadcastCommerce(data: ShareBroadcastCommerce): void {}

  inviteContact(): void {}

  hasMethod(functionName: string): boolean {
    return false;
  }

  selectTemplateFromList(): void {
    window.parent.postMessage({ type: 'selectTemplate' }, '*', []);
  }

  openCustomerDetailActivity(
    customerName: string,
    customerPhoneNumber: string
  ): void {}

  openDeepLink(url: string): void {}

  openLink(url: string): void {
    openPopup(url, '_blank');
  }

  trackEvent(
    eventName: AnalyticsEventNames,
    properties?: AnalyticsEventOptions
  ): void {
    window.parent.postMessage(
      { type: 'TRACK_EVENT', data: { eventName, properties } },
      '*',
      []
    );
  }

  finishActivityWithError(message: string): void {
    window.parent.postMessage({ type: 'CLOSE_IFRAME' }, '*', []);
  }

  openCustomerChat(data: OpenCustomerChatData): void {
    window.parent.postMessage(
      { type: 'OPEN_CUSTOMER_CHAT', data: data },
      '*',
      []
    );
  }

  sendDataDebug(data: any): void {}

  redirect(url: string): void {
    window.parent.postMessage({ type: 'REDIRECT', data: { url } }, '*', []);
  }

  setRoutePath(path: Partial<NavigatePath>, options?: NavigateOptions): void {
    window.parent.postMessage(
      { type: 'ROUTE_PATH', data: { path, options } },
      '*',
      []
    );
  }

  copyToClipboard(data: string): Promise<any> {
    const input = document.createElement('textarea');
    input.innerHTML = data;
    document.body.appendChild(input);
    input.select();
    const result = document.execCommand('copy');
    document.body.removeChild(input);
    return result ? Promise.resolve('copied') : Promise.reject('failed');
  }

  shareData(data: string): void {
    this.openLink(`https://api.whatsapp.com/send?text=${data}`);
  }

  finishActivityWithResultOk(): void {}

  deleteCustomField(customFieldId: string, type: CustomFieldsTypes): void {
    window.parent.postMessage(
      { type: 'DELETE_CUSTOM_FIELD', data: { id: customFieldId, type } },
      '*',
      []
    );
  }

  updateCustomField(customFieldId: string, type: CustomFieldsTypes): void {
    window.parent.postMessage(
      { type: 'UPDATE_CUSTOM_FIELD', data: { id: customFieldId, type } },
      '*',
      []
    );
  }

  createCustomField(customFieldId: string, type: CustomFieldsTypes): void {
    window.parent.postMessage(
      { type: 'CREATE_CUSTOM_FIELD', data: { id: customFieldId, type } },
      '*',
      []
    );
  }

  retargetTemplate(data: { action: string; count: number }): void {
    window.parent.postMessage({ type: 'RETARGET_TEMPLATE', data }, '*', []);
  }

  getTemplateData(id: string): void {
    window.parent.postMessage(
      { type: 'GET_TEMPLATE_DATA', data: { id } },
      '*',
      []
    );
  }

  sendCurlForTemplateAsApi(curl: string): void {
    window.parent.postMessage(
      { type: 'SEND_CURL_FOR_TEMPLATE_AS_API', data: { curl } },
      '*',
      []
    );
  }

  onAddToBroadcastList(): void {
    window.parent.postMessage(
      { type: 'ADD_BROADCAST_SUCCESS', data: {} },
      '*',
      []
    );
  }

  getSelectedCustomerIds(): void {
    window.parent.postMessage(
      { type: 'GET_SELECTED_CUSTOMER_IDS', data: {} },
      '*',
      []
    );
  }

  changeCustomToken(token: string, iframeId: string): void {
    window.parent.postMessage(
      { type: 'CHANGE_CUSTOM_TOKEN', data: { token, iframeId } },
      '*',
      []
    );
  }
  
  // changeChannelPartner(token: string, iframeId: string): void {
  //   window.parent.postMessage(
  //     { type: 'CHANGE_CUSTOM_TOKEN', data: { token, iframeId } },
  //     '*',
  //     []
  //   );
  // }

  async getCurrentPath(iframeId: string): Promise<CurrentPath> {
    return new Promise((resolve) => {
      this.cachedPromise.currentPath = {
        cachedPromiseResolve: resolve,
      };

      window.parent.postMessage(
        { type: 'GET_CURRENT_PATH', data: { iframeId } },
        '*',
        []
      );
    });
  }

  async dialog360Connect(): Promise<{
    client: string;
    channels: string[];
    revokedChannels?: string[];
  }> {
    return Promise.resolve({
      client: '',
      channels: [],
      revokedChannels: undefined,
    });
  }

  openBotBuilderModal(botId: string): void {
    window.parent.postMessage(
      { type: 'SET_BOT_BUILDER_MODAL_OPEN', botId },
      '*',
      []
    );
  }

  closeBotBuilderModal(): void {
    window.parent.postMessage(
      { type: 'SET_BOT_BUILDER_MODAL_CLOSED' },
      '*',
      []
    );
  }

  openImportExcelModal(): void {
    window.parent.postMessage({ type: 'OPEN_IMPORT_EXCEL_MODAL' }, '*', []);
  }

  openAutoRetrySettingsModal(broadcastId: string): void {
    window.parent.postMessage(
      { type: 'OPEN_AUTO_RETRY_SETTINGS_MODAL', data: { broadcastId } },
      '*',
      []
    );
  }

  setAutoRetry(broadcastId: string, state: FrequencyCappingState): void {
    window.parent.postMessage(
      { type: 'SET_AUTO_RETRY', data: { state, broadcastId } },
      '*',
      []
    );
  }

  setUserStatus(
    status: WabaIntegrationMemberStatus | null,
    statusExpiration: string | null
  ): void {
    window.parent.postMessage(
      { type: 'SET_USER_STATUS', data: { status, statusExpiration } },
      '*',
      []
    );
  }

  sendChannelMembersInfo(
    groupId: string,
    memberCount: number,
    showMemberCount: boolean
  ): void {
    window.parent.postMessage(
      {
        type: 'SEND_CHANNEL_MEMBERS_INFO',
        data: { groupId, memberCount, showMemberCount },
      },
      '*',
      []
    );
  }

  switchOrganisation(orgId: string): void {
    window.parent.postMessage({ type: 'SWITCH_ORG', data: { orgId } }, '*', []);
  }
}
