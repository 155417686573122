import BasePlatform from './base';
import Android from './android';
import IOS from './ios';
import Web from './web';

let platform!: BasePlatform;

const androidPlatform = new Android();
const iosPlatform = new IOS();
const webPlatform = new Web();

if (androidPlatform.isCurrentPlatform()) {
  platform = androidPlatform;
} else if (iosPlatform.isCurrentPlatform()) {
  platform = iosPlatform;
} else {
  // to start the event listener
  webPlatform.isCurrentPlatform();
  platform = webPlatform;
}

// TODO add platform unsupported code in index.js. Convert direct execution to a function execution
export default platform;
