import platform from '../../infra/platform';
import Android from '../../infra/platform/android';
import IOS from '../../infra/platform/ios';
import Web from '../../infra/platform/web';

export const isDesktop = () => platform instanceof Web;

export const isAndroid = () => platform instanceof Android;

export const isIOS = () => platform instanceof IOS;

export const isMobile = () => isAndroid() || isIOS();
