import { atom } from 'jotai';
import { createRequestAtom } from '../../../../shared/utils/request-atom';
import { AccessLevelDetails, ChannelAccessDetails } from '../typings';

export const channelAccessDetailsAtom = createRequestAtom<
  ChannelAccessDetails | undefined
>(undefined);

export const accessLevelAtom = createRequestAtom<AccessLevelDetails[]>();

export const toggleSelectedBroadcastIdsAtom = atom<boolean>(false);
export const selectedBroadcastIdsListAtom = atom<string[]>([]);
