import React, { forwardRef } from 'react';
import { Button, ButtonProps } from '@mui/material';

const QSButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ sx, variant = 'contained', ...props }, ref) => {
    return (
      <Button
        ref={ref}
        variant={variant}
        sx={{
          borderRadius: '4px',
          ...(variant === 'contained'
            ? { backgroundColor: 'rgba(63,144,109, 1)' }
            : { color: `rgb(63,144,109)`, textTransform: 'capitalize' }),
          ...sx,
        }}
        {...props}
      />
    );
  }
);


export default QSButton;
