import { useEffect } from 'react';
import { RefreshTypes } from '../../infra/platform/android';

export interface UseRefreshArgs {
  refreshEventType?: RefreshTypes;
  onRefresh: () => void;
}

export default function useRefresh({ refreshEventType, onRefresh }: UseRefreshArgs): void {
  useEffect(() => {
    const refreshEventHandler = (event: CustomEvent<{ refreshType: string }>) => {
      const refreshType = event.detail.refreshType;
      if (refreshType === refreshEventType) {
        onRefresh();
      }
    };
    document.addEventListener('reloadpagedata', refreshEventHandler);
    return () => {
      document.removeEventListener('reloadpagedata', refreshEventHandler);
    }
  },
  [refreshEventType, onRefresh]);
}
