import { Dispatch, useEffect, useReducer } from 'react';
import loadAsyncPolyfills from './';
import polyfillReducer, {
  initialPolyfillState,
  PolyfillReducerActionTypes,
} from './reducer';

const loadPolyfills = async (
  dispatch: Dispatch<PolyfillReducerActionTypes>
) => {
  dispatch(PolyfillReducerActionTypes.LOADING);
  try {
    await loadAsyncPolyfills();
    dispatch(PolyfillReducerActionTypes.DONE);
  } catch (error) {
    dispatch(PolyfillReducerActionTypes.ERROR);
  }
};

export default function usePolyfills() {
  const [polyfillState, dispatchPolyfillChange] = useReducer(
    polyfillReducer,
    initialPolyfillState
  );

  useEffect(() => {
    loadPolyfills(dispatchPolyfillChange);
  }, []);

  return polyfillState;
}
