import { atom } from 'jotai';
import { Country } from '../../../../shared/typings';
import { createRequestAtom } from '../../../../shared/utils/request-atom';
import { UserProfileData } from '../typings';

const userProfileAtom = createRequestAtom<UserProfileData>();

export const userPhoneDataAtom = atom<{ country: Country } | undefined>(undefined);

export default userProfileAtom;
