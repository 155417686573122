import { BoxProps, Box } from '@mui/material';
import { forwardRef } from 'react';

const QSBox = forwardRef((props: BoxProps,ref) => {
  return <Box ref={ref} {...props} />;
})

export default QSBox;

export type QSBoxProps = BoxProps;
