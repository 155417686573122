/** @jsxImportSource @emotion/react */
import { StrictMode, Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import App from './modules/app/components/App';
import { css, Global } from '@emotion/react';
import resetStyles from './shared/styles/reset';
import { register } from './service-worker-registration';
import * as Sentry from '@sentry/react';
import ErrorContainer from './shared/components/molecules/error';
import { APP_BACKGROUD_COLOR } from './shared/typings';
import i18NInit from './i18N';
import { NotificationsProvider } from './shared/modules/notifications/v1/components';
import { isDesktop } from './shared/utils/platform';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const updateServiceWorker = (registration: ServiceWorkerRegistration) => {
  if (registration.waiting) {
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    navigator.serviceWorker.oncontrollerchange = () => {
      window.location.reload();
    };
  }
};

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://e39ac2fbc773424993f635416af2def4@o110511.ingest.sentry.io/4503959334420480',
    beforeSend(event, hint) {
      if (
        event.message?.includes('ResizeObserver') ||
        event.exception?.values?.[0]?.type?.includes('ResizeObserver') ||
        event.exception?.values?.[0]?.type === 'ResizeObserver loop limit exceeded'
      ) {
        return null;
      }
      return event;
    },
    ignoreErrors: [
      'ResizeObserver',
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  });
}

i18NInit();

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        ${resetStyles}

        html,
        body,
        #root {
          margin: 0px;
          padding: 0px;
          height: 100%;
          width: 100%;
          background: ${APP_BACKGROUD_COLOR};
          font-family: ${[isDesktop() ? 'inter' : 'Roboto', 'sans-serif',].join(',')};
        }

        * {
          -webkit-tap-highlight-color: transparent;
          font-family: inherit;
        }

        *,
        :after,
        :before {
          box-sizing: border-box;
        }

        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0px;
        }

        @keyframes spinner {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        @keyframes shrinker {
          0% {
            stroke-dasharray: 1px, 200px;
            stroke-dashoffset: 0;
          }
          50% {
            stroke-dasharray: 100px, 200px;
            stroke-dashoffset: -15px;
          }
          100% {
            stroke-dasharray: 100px, 200px;
            stroke-dashoffset: -125px;
          }
        }

        @keyframes analytics-widget-flash {
          0% {
            background-color: #ffffff;
          }
          25% {
            background-color: rgba(0, 0, 0, 0.04);
          }
          50% {
            background-color: rgba(93, 183, 121, 0.10);
          }
          75% {
            background-color: rgba(0, 0, 0, 0.04);
          }
          100% {
            background-color: #ffffff;
          }
        }
      `}
    />
  );
}

root.render(
  <StrictMode>
    <Sentry.ErrorBoundary
      fallback={
        <Fragment>
          <GlobalStyles />
          <ErrorContainer
            customCss={css`
              height: 100%;
              width: 100%;
              padding: 16px;
            `}
          />
        </Fragment>
      }
    >
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <App updateServiceWorker={updateServiceWorker} />
        <NotificationsProvider maxNotifications={5} />
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>
);

register({
  onUpdate: (registration) => {
    const customEvent = new CustomEvent('updateServiceWorker', {
      detail: { registration },
    });
    document.dispatchEvent(customEvent);
  },
});
