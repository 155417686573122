import { atom } from 'jotai';
import { createRequestAtom } from '../utils/request-atom';
import { AllUserPermissions, Permissions } from '../typings/user';
import userProfileAtom from '../../modules/user-profile/v1/state';
import { UserStatusData } from '../../modules/user-profile/v1/typings';

export const rootScope = Symbol('rootScope');

const getPermission = (
  permission: Permissions,
  permissions: Set<string>,
  resources: Map<string, Array<string>>
) => ({
  hasAccess: permissions.has(permission),
  resources: resources.get(permission) || [],
});

export const userStatusAtom = createRequestAtom<UserStatusData>();

export const allUserPermissions = atom<AllUserPermissions>((get) => {
  const profile = get(userProfileAtom)?.data;

  let permissions = new Set<string>();
  const resourcesMap = new Map<string, Array<string>>();

  if (profile) {
    const roles = profile.access.roles;

    roles.forEach((role) => {
      const policies = role.policies;

      policies.forEach((policy) => {
        let resources = policy.resources.map(({ id }) => id);

        if (resources.length === 0) {
          resources = ['*'];
        }

        permissions.add(policy.permissionId);
        resourcesMap.set(policy.permissionId, resources);
      });
    });
  }

  return {
    canReadConversation: getPermission(
      Permissions.READ_ALL_CONVERSATIONS,
      permissions,
      resourcesMap
    ),
    canWriteConversation: getPermission(
      Permissions.WRITE_ALL_CONVERSATIONS,
      permissions,
      resourcesMap
    ),
    canReadAssignedConversation: getPermission(
      Permissions.READ_ASSIGNED_CONVERSATIONS,
      permissions,
      resourcesMap
    ),
    canReadUnassignedConversation: getPermission(
      Permissions.READ_UNASSIGNED_CONVERSATIONS,
      permissions,
      resourcesMap
    ),
    canWriteUnassignedConversation: getPermission(
      Permissions.READ_UNASSIGNED_CONVERSATIONS,
      permissions,
      resourcesMap
    ),
    canWriteAssignedConversation: getPermission(
      Permissions.WRITE_ASSIGNED_CONVERSATIONS,
      permissions,
      resourcesMap
    ),
    canReadMembers: getPermission(
      Permissions.READ_TEAM_MEMBERS,
      permissions,
      resourcesMap
    ),
    canAddMembers: getPermission(
      Permissions.ADD_TEAM_MEMBERS,
      permissions,
      resourcesMap
    ),
    canRemoveMembers: getPermission(
      Permissions.DELETE_TEAM_MEMBERS,
      permissions,
      resourcesMap
    ),
    canUpdateMembers: getPermission(
      Permissions.UPDATE_TEAM_MEMBERS,
      permissions,
      resourcesMap
    ),
    canAddIntegration: getPermission(
      Permissions.ADD_INTEGRATION,
      permissions,
      resourcesMap
    ),
    canConnectIntegration: getPermission(
      Permissions.CONNECT_INTEGRATION,
      permissions,
      resourcesMap
    ),
    canDisconnectIntegration: getPermission(
      Permissions.DISCONNECT_INTEGRATION,
      permissions,
      resourcesMap
    ),
    canReadIntegration: getPermission(
      Permissions.GET_INTEGRATIONS,
      permissions,
      resourcesMap
    ),
    canGetCustomers: getPermission(
      Permissions.GET_CUSTOMERS,
      permissions,
      resourcesMap
    ),
    canManageWallet: getPermission(
      Permissions.MANAGE_WALLET,
      permissions,
      resourcesMap
    ),
    canManageChannelAccess: getPermission(
      Permissions.MANAGE_CHANNEL_ACCESS,
      permissions,
      resourcesMap
    ),
    canManageCreatedChannelAccess: getPermission(
      Permissions.MANAGE_CREATED_CHANNEL_ACCESS,
      permissions,
      resourcesMap
    ),
    channelAccessLevelFullAccess: getPermission(
      Permissions.CHANNEL_ACCESS_LEVEL_FULL_ACCESS,
      permissions,
      resourcesMap
    ),
    channelAccessLevelSendOnlyAccess: getPermission(
      Permissions.CHANNEL_ACCESS_LEVEL_SEND_ONLY_ACCESS,
      permissions,
      resourcesMap
    ),
    channelAccessLevelNoAccess: getPermission(
      Permissions.CHANNEL_ACCESS_LEVEL_NO_ACCESS,
      permissions,
      resourcesMap
    ),
    canReadCustomerPhone: getPermission(
      Permissions.READ_CUSTOMER_PHONE,
      permissions,
      resourcesMap
    ),
    canUpdateWebhooks: getPermission(
      Permissions.UPDATE_WEBHOOKS,
      permissions,
      resourcesMap
    ),
    canAddWebhooks: getPermission(
      Permissions.ADD_WEBHOOKS,
      permissions,
      resourcesMap
    ),
    canDeleteWebhooks: getPermission(
      Permissions.DELETE_WEBHOOKS,
      permissions,
      resourcesMap
    ),
    canReadWebhooks: getPermission(
      Permissions.READ_WEBHOOKS,
      permissions,
      resourcesMap
    ),
    canChangeBotStatus: getPermission(
      Permissions.CHANGE_BOT_STATUS,
      permissions,
      resourcesMap
    ),
    canEditBotMessage: getPermission(
      Permissions.EDIT_BOT_MESSAGE,
      permissions,
      resourcesMap
    ),
    canDeleteBotKeyword: getPermission(
      Permissions.DELETE_BOT_KEYWORD,
      permissions,
      resourcesMap
    ),
    canManageRoles: getPermission(
      Permissions.MANAGE_ROLES,
      permissions,
      resourcesMap
    ),
    canGetBillingDetails: getPermission(
      Permissions.GET_BILLING_DETAILS,
      permissions,
      resourcesMap
    ),
    canUpdateBillingDetails: getPermission(
      Permissions.UPDATE_BILLING_DETAILS,
      permissions,
      resourcesMap
    ),
    canGetWalletInvoices: getPermission(
      Permissions.GET_WALLET_INVOICES,
      permissions,
      resourcesMap
    ),
    canDownloadWalletInvoices: getPermission(
      Permissions.DOWNLOAD_WALLET_INVOICES,
      permissions,
      resourcesMap
    ),
    canGetSubscriptionInvoices: getPermission(
      Permissions.GET_SUBSCRIPTION_INVOICES,
      permissions,
      resourcesMap
    ),
    canDownloadSubscriptionInvoices: getPermission(
      Permissions.DOWNLOAD_SUBSCRIPTION_INVOICES,
      permissions,
      resourcesMap
    ),
    canGetWabaUsageInvoices: getPermission(
      Permissions.GET_WABA_USAGE_SUMMARY,
      permissions,
      resourcesMap
    ),
    canDownloadWabaUsageInvoices: getPermission(
      Permissions.DOWNLOAD_WABA_USAGE_SUMMARY,
      permissions,
      resourcesMap
    ),
    canGetAnalyticsWidgets: getPermission(
      Permissions.GET_ANALYTICS_WIDGETS,
      permissions,
      resourcesMap
    ),
    canWriteAnalyticsWidgets: getPermission(
      Permissions.WRITE_ANALYTICS_WIDGETS,
      permissions,
      resourcesMap
    ),
    canDeleteAnalyticsWidgets: getPermission(
      Permissions.DELETE_ANALYTICS_WIDGETS,
      permissions,
      resourcesMap
    ),
    canReadWabaIntegrations: getPermission(
      Permissions.READ_WABA_INTEGRATIONS,
      permissions,
      resourcesMap
    ),
    canWriteWabaIntegrations: getPermission(
      Permissions.WRITE_WABA_INTEGRATIONS,
      permissions,
      resourcesMap
    ),
    canReadTeamAssignedConversation: getPermission(
      Permissions.READ_TEAM_ASSIGNED_CONVERSATIONS,
      permissions,
      resourcesMap
    ),
  };
});

export const canReadConversationAtom = atom(
  (get) => get(allUserPermissions).canReadConversation
);

export const canWriteConversationAtom = atom(
  (get) => get(allUserPermissions).canWriteConversation
);

export const canReadMembersAtom = atom(
  (get) => get(allUserPermissions).canReadMembers
);

export const canAddMembersAtom = atom(
  (get) => get(allUserPermissions).canAddMembers
);

export const canRemoveMembersAtom = atom(
  (get) => get(allUserPermissions).canRemoveMembers
);

export const canUpdateMembersAtom = atom(
  (get) => get(allUserPermissions).canUpdateMembers
);

export const canAddIntegrationAtom = atom(
  (get) => get(allUserPermissions).canAddIntegration
);

export const canConnectIntegrationAtom = atom(
  (get) => get(allUserPermissions).canConnectIntegration
);

export const canDisconnectIntegrationAtom = atom(
  (get) => get(allUserPermissions).canDisconnectIntegration
);

export const canReadIntegrationAtom = atom(
  (get) => get(allUserPermissions).canReadIntegration
);

export const canReadAssignedConversationAtom = atom(
  (get) => get(allUserPermissions).canReadAssignedConversation
);

export const canWriteAssignedConversationAtom = atom(
  (get) => get(allUserPermissions).canWriteAssignedConversation
);

export const canReadUnassignedConversationAtom = atom(
  (get) => get(allUserPermissions).canReadUnassignedConversation
);

export const canWriteUnassignedConversationAtom = atom(
  (get) => get(allUserPermissions).canWriteUnassignedConversation
);

export const canGetCustomersAtom = atom(
  (get) => get(allUserPermissions).canGetCustomers
);

export const canManageWalletAtom = atom(
  (get) => get(allUserPermissions).canManageWallet
);

export const userRoleAtom = atom(
  (get) => get(userProfileAtom)?.data?.access?.role
);

export const canAddWebhookAtom = atom(
  (get) => get(allUserPermissions).canAddWebhooks
);

export const canUpdateWebhookAtom = atom(
  (get) => get(allUserPermissions).canUpdateWebhooks
);

export const canDeleteWebhookAtom = atom(
  (get) => get(allUserPermissions).canDeleteWebhooks
);

export const canReadWebhookAtom = atom(
  (get) => get(allUserPermissions).canReadWebhooks
);

export const canDeleteBotKeywordAtom = atom(
  (get) => get(allUserPermissions).canDeleteBotKeyword
);

export const canEditBotMessageAtom = atom(
  (get) => get(allUserPermissions).canEditBotMessage
);

export const canChangeBotStatusAtom = atom(
  (get) => get(allUserPermissions).canChangeBotStatus
);

export const canManageRoles = atom(
  (get) => get(allUserPermissions).canManageRoles
);

export const canGetBillingDetails = atom(
  (get) => get(allUserPermissions).canGetBillingDetails
);

export const canUpdateBillingDetails = atom(
  (get) => get(allUserPermissions).canUpdateBillingDetails
);

export const canGetWalletInvoices = atom(
  (get) => get(allUserPermissions).canGetWalletInvoices
);

export const canDownloadWalletInvoices = atom(
  (get) => get(allUserPermissions).canDownloadWalletInvoices
);

export const canGetSubscriptionInvoices = atom(
  (get) => get(allUserPermissions).canGetSubscriptionInvoices
);

export const canDownloadSubscriptionInvoices = atom(
  (get) => get(allUserPermissions).canDownloadSubscriptionInvoices
);

export const canGetWabaUsageInvoices = atom(
  (get) => get(allUserPermissions).canGetWabaUsageInvoices
);

export const canDownloadWabaUsageInvoices = atom(
  (get) => get(allUserPermissions).canDownloadWabaUsageInvoices
);

export const canGetAnalyticsWidgets = atom(
  (get) => get(allUserPermissions).canGetAnalyticsWidgets
);

export const canWriteAnalyticsWidgets = atom(
  (get) => get(allUserPermissions).canWriteAnalyticsWidgets
);

export const canDeleteAnalyticsWidgets = atom(
  (get) => get(allUserPermissions).canDeleteAnalyticsWidgets
);

export const canReadWabaIntegrations = atom(
  (get) => get(allUserPermissions).canReadWabaIntegrations
);

export const canWriteWabaIntegrations = atom(
  (get) => get(allUserPermissions).canWriteWabaIntegrations
);
