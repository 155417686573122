async function loadIntersectionObserver() {
  if (
    !(
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'isIntersecting' in window.IntersectionObserverEntry.prototype
    )
  ) {
    // @ts-ignore Just a polyfill, default TS Dom types will be used.
    await import('intersection-observer');
  }
}

export default loadIntersectionObserver;
