import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { APP_BACKGROUD_COLOR } from './shared/typings';
import { isDesktop } from './shared/utils/platform';

// A custom theme for this app
const theme = createTheme({
  palette: {
    background: {
      default: APP_BACKGROUD_COLOR,
    },
    error: {
      main: red.A400,
    },
    primary: {
      main: '#3F906D',
    },
    text: {
      primary: '#2d2d2d',
    },
  },
  typography: {
    fontFamily: [isDesktop() ? 'inter' : 'Roboto', 'sans-serif'].join(','),
    button: {
      textTransform: 'none',
    },
  },
});
export default theme;
