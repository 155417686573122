import { createTheme } from '@mui/material/styles';
import { QueryRequest } from '../../../../shared/typings/request';
import { FiltersParamsI } from '../state/filters';

export enum ReducerActionTypes {
  'GET' = 'GET',
}

export enum CURRENCY_ALIGNMENT {
  left = 'left',
  right = 'right',
}

export interface LinkDetails {
  chatId: string;
}

export interface SelectedCatalogue {
  title: string;
  catalogueId: string;
  productCount: number;
  productsMatched?: boolean;
}

export interface SelectedProduct {
  title: string;
  productId: string;
}
export interface SelectedProducts {
  productId: string;
  catalogueId: string;
}

export interface CheckedProducts {
  productIds: Map<string, Map<string, boolean>>;
}
export interface ProductsPaginationData {
  afterProductId?: string | undefined;
  afterPosition?: number | undefined;
  afterPageNumber?: number | undefined;
  hasMore: boolean;
}

export interface CatalogueProduct {
  readonly productId: string;
  readonly position: number;
  readonly pictureUrl: string;
}

export interface UserAndCompanyDetails {
  currencySymbol: string;
  currencyCode: string;
  currencyAlignment?: CURRENCY_ALIGNMENT;
  companyName: string;
  userName: string;
}
export interface Catalogue {
  readonly id: string;
  readonly title: string;
  readonly pictureUrl: string;
  readonly productCount: number;
  readonly productsMatched?: boolean;
}

export interface CataloguesPaginationData {
  afterCatalogueId: string;
  afterPageNumber: number;
  hasMore: boolean;
}

export interface ProductPicture {
  readonly id: string;
  readonly url: string;
  readonly height: number;
  readonly width: number;
}

export interface Variant {
  readonly variantId: string;
  readonly canShare: boolean;
}

export interface VariantValue {
  rgbValue: number[] | null;
  variantType: string;
  variantValue: string;
}

export interface VariantListData {
  readonly products: Product[];
}

export interface Product {
  readonly id: string;
  readonly catalogueId: string;
  readonly name: string | null;
  readonly sku: string | null;
  readonly price: number | null;
  readonly discountedPrice: number | null;
  readonly pictureUrl: string | null;
  readonly defaultPictureId: string | null;
  readonly pictures: ProductPicture[] | null;
  readonly dateCreated: string;
  readonly canShare: boolean;
  readonly variants?: Variant[];
  readonly variantValues: VariantValue[];
}

export interface CataloguesListData {
  readonly catalogues: Catalogue[];
}

export interface ProductsListData {
  readonly nextProductId?: string;
  readonly nextDateCreated?: string;
  readonly products: Product[];
}
export enum CatalogueViewRequestTabs {
  Pending = 'pending',
  Approved = 'approved',
}
export interface CatalogueApprovalPaginationData {
  lastDateRequested: string;
}
export interface PendingCatalogues {
  id: string;
  title: string;
  link: string;
}

export interface PendingCataloguesData {
  name: string;
  phoneNumber: string;
  dateRequested: string;
  visitingCardUrl: string;
  catalogues: PendingCatalogues[];
}

export interface PendingCatalogueApprovalsData {
  requests: PendingCataloguesData[];
  lastDateRequested: string;
}

export interface Variant {
  id: string;
}

export interface ProductsList {
  productId: string;
  variants: Variant[];
}

export interface CatalogueProductsList {
  catalogueId: string;
  productsList: ProductsList[];
}

export interface CatalogueProductsListData {
  catalogueProductsList: CatalogueProductsList[];
}
export interface CataloguesListReducerActions
  extends QueryRequest<CataloguesListData> {
  readonly type: ReducerActionTypes;
}

export interface CommerceScrollPos {
  position: number;
}
export interface CatalogueSearchAtom {
  isSearchActive: boolean;
  searchTerm: string;
  lastCatalogueId: string;
}
export interface ProductSearchAtom {
  isSearchActive: boolean;
  searchTerm: string;
  lastCatalogueId: string;
  pageNumber: number;
}

interface CommerceData {
  chatId: string;
  customerId: string;
  text: string;
}

export interface ShareCommerce {
  data: CommerceData | CommerceData[];
  closeCommerce?: boolean;
}
export interface ShareBroadcastCommerce {
  catalogueIds: string[];
  groupId: string;
  chatId: string;
}

export interface ProductMap {
  productMap: Map<string, boolean>;
}
export interface SelectedProductsMap {
  selectedProductsMap: Map<string, ProductMap>;
}

export enum CommerceTypes {
  CATALOGUE = 'catalogue',
  PRODUCT = 'product',
}

export enum CommerceEntityTypes {
  PRODUCTS = 'PRODUCTS',
  CATALOGUES = 'CATALOGUES',
}

export interface ShareCollectionFilters {
  filters: FiltersParamsI | undefined;
}

export interface CommerceEntity {
  type: CommerceEntityTypes;
  ids: string[];
  excludeChildIds?: string[];
  filterData?: ShareCollectionFilters;
}
export interface CommerceEntities {
  entities: CommerceEntity[];
  chatId: string;
}

export interface Entity {
  type: string;
  ids: string[];
}

export interface RootObject {
  entities: Entity[];
  chatId: string;
}

export enum CommerceModalTypes {
  SHARE_PHOTOS = 'SHARE_PHOTOS',
  SHARE_LINKS = 'SHARE_LINKS',
}

export enum IntegrationNames {
  QUICKSELL = 'Quicksell',
  WHATSAPP = 'Whatsapp',
}

export enum IntegrationStatus {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}
export interface ModalData {
  name: string;
  subtitle?: string;
  type: CommerceModalTypes;
  onClick: () => Promise<any>;
}

export const CATALOGUES_TAB_HASH = { index: 0, href: '#catalogues' };

export const PRODUCTS_TAB_HASH = { index: 1, href: '#products' };

export const ACCENT_COLOR = '#3F906D';

export const BACKGROUND_COLOR = '#FBFBFC';

export const WHITE_COLOR = '#FFFFFF';

export const BLACK_COLOR = '#2d2d2d';

export const TEXT_COLOR = '#010101';

export const BOX_SHADOW = '0px 1px 1px rgba(210, 210, 210, 0.5)';

export const CHECKBOX_BOX_SHADOW =
  'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px';

export const BORDER_COLOR = '#DADADA';

export const GRID_TILE_SPACING = '9px';

export const PAGE_PADDING = '18px';

export const COMMERCE_PADDING = '18px 18px 70px';

export const BUTTON_HEIGHT = '42px';

export const HEADER_HEIGHT = '55px';

export const TABS_HEIGHT = '48px';

export const AVATAR_SIZE = '50px';

export const CHECKBOX_SIZE = '20px';

export const CATALOGUE_LIST_ITEM_HEIGHT = '82px';

export const PRODUCT_PAGE_CATALOGUE_LIST_ITEM_HEIGHT = '82px';

export const CARD_BORDER_RADIUS = '8px';

export const VIRTUAL_LIST_IMAGE_DEFAULT_HEIGHT = '180px';

export const VIRTUAL_LIST_OVERSCAN_COUNT = 3;

export const theme = createTheme({
  palette: {
    primary: {
      main: ACCENT_COLOR,
    },
    secondary: {
      main: BACKGROUND_COLOR,
    },
    text: {
      primary: BLACK_COLOR,
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          borderBottom: `2px solid ${BORDER_COLOR}`,
          textTransform: 'none',
          color: BLACK_COLOR,
          opacity: 1,
        },
      },
    },
  },
});
