import { useLayoutEffect, useRef } from 'react';

let stashedTime = 0;

export const useSyncAnimations = (animationName: string) => {
  const ref = useRef<any>(null);

  useLayoutEffect(() => {
    if ('getAnimations' in window) {
      const animations = document.getAnimations().filter(
        (
          animation: Animation & {
            animationName?: string;
          }
        ) => {
          if ('animationName' in animation!) {
            return animation.animationName! === animationName;
          }

          return false;
        }
      );

      const targetAnimation = animations.find((animation) => {
        if (animation.effect instanceof KeyframeEffect) {
          return animation.effect.target === ref.current;
        }

        return false;
      });

      if (!targetAnimation) return;

      const firstAnimation = animations[0];

      if (targetAnimation === firstAnimation && stashedTime) {
        targetAnimation.currentTime = stashedTime;
      }

      if (targetAnimation !== firstAnimation) {
        targetAnimation.currentTime = firstAnimation.currentTime;
      }

      return () => {
        if (targetAnimation === firstAnimation) {
          stashedTime = targetAnimation.currentTime as number ?? 0;
        }
      };
    }
  }, [animationName]);

  return ref;
};
