import { SyntheticEvent, KeyboardEvent, Fragment, useCallback, useRef, useState, useEffect, ReactNode, MouseEvent } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { ButtonProps, ExtendButtonBase, IconButton, IconButtonTypeMap, SxProps } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';

export interface MenuItemI {
  id: string;
  title: string;
  value?: string;
}

export interface MenuProps<T = string | number | undefined> {
  show: boolean;
  getMenuItems?: () => MenuItemI[] | null;
  onSelectMenuItem?: (menuItem: MenuItemI, event?: Event | SyntheticEvent) => void;
  moreVertButtonProps?: ButtonProps;
  renderOption?: (option: { id?: string; title: T; value?: T }) => ReactNode;
  PopperSx?: SxProps; 
  autoFocusItem?: boolean;
}

export default function MenuDropdown({
  show,
  getMenuItems,
  onSelectMenuItem,
  moreVertButtonProps,
  renderOption,
  PopperSx,
  autoFocusItem = true
}: MenuProps) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    return;
  };
  const handleClose = (event: Event | SyntheticEvent) => {
    event.stopPropagation();
    setOpen(false);
    setAnchorEl(null);
    return;
  };

  const handleOnSelectMenuItem = useCallback(
    (event: Event | SyntheticEvent, selectedMenu: MenuItemI) => {
      if (typeof onSelectMenuItem !== 'function') {
        return;
      }
      onSelectMenuItem(selectedMenu, event);
      handleClose(event);
    },
    [handleClose, onSelectMenuItem]
  );

  function handleListKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  if (!show || typeof getMenuItems !== 'function') {
    return null;
  }

  const menuItems = getMenuItems();

  if (!menuItems || !menuItems.length) {
    return null;
  }

  return (
    <Fragment>
      <IconButton
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          handleOpen(event);
          handleToggle();
        }}
        {...moreVertButtonProps}
      >
        <MoreVert />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        sx={{
          zIndex: 9999,
          ...PopperSx,
        }}
        transition={true}
        disablePortal={true}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList variant='selectedMenu' autoFocusItem={autoFocusItem} onKeyDown={handleListKeyDown}>
                  {menuItems.map((menuItem, index) => {
                    const { title, id, value } = menuItem;
                    return (
                      <MenuItem
                        key={index}
                        onClick={(event) =>
                          handleOnSelectMenuItem(event, menuItem)
                        }
                      >
                        {typeof renderOption === 'function'
                          ? renderOption({ id, title, value })
                          : title}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
}
