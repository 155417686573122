export enum Permissions {
  READ_ALL_CONVERSATIONS = 'READ_ALL_CONVERSATIONS',
  WRITE_ALL_CONVERSATIONS = 'WRITE_ALL_CONVERSATIONS',
  READ_ASSIGNED_CONVERSATIONS = 'READ_ASSIGNED_CONVERSATIONS',
  WRITE_ASSIGNED_CONVERSATIONS = 'WRITE_ASSIGNED_CONVERSATIONS',
  READ_UNASSIGNED_CONVERSATIONS = 'READ_UNASSIGNED_CONVERSATIONS',
  WRITE_UNASSIGNED_CONVERSATIONS = 'WRITE_UNASSIGNED_CONVERSATIONS',
  ADD_TEAM_MEMBERS = 'ADD_TEAM_MEMBERS',
  DELETE_TEAM_MEMBERS = 'DELETE_TEAM_MEMBERS',
  READ_TEAM_MEMBERS = 'READ_TEAM_MEMBERS',
  UPDATE_TEAM_MEMBERS = 'UPDATE_TEAM_MEMBERS',
  GET_INTEGRATIONS = 'GET_INTEGRATIONS',
  ADD_INTEGRATION = 'ADD_INTEGRATION',
  CONNECT_INTEGRATION = 'CONNECT_INTEGRATION',
  DISCONNECT_INTEGRATION = 'DISCONNECT_INTEGRATION',
  GET_CUSTOMERS = 'GET_CUSTOMERS',
  MANAGE_WALLET = 'MANAGE_WALLET',
  MANAGE_CHANNEL_ACCESS = 'MANAGE_CHANNEL_ACCESS',
  MANAGE_CREATED_CHANNEL_ACCESS = 'MANAGE_CREATED_CHANNEL_ACCESS',
  CHANNEL_ACCESS_LEVEL_FULL_ACCESS = 'CHANNEL_ACCESS_LEVEL_FULL_ACCESS',
  CHANNEL_ACCESS_LEVEL_SEND_ONLY_ACCESS = 'CHANNEL_ACCESS_LEVEL_SEND_ONLY_ACCESS',
  CHANNEL_ACCESS_LEVEL_NO_ACCESS = 'CHANNEL_ACCESS_LEVEL_NO_ACCESS',
  READ_CUSTOMER_PHONE = 'READ_CUSTOMER_PHONE',
  ADD_WEBHOOKS = 'ADD_WEBHOOKS',
  UPDATE_WEBHOOKS = 'UPDATE_WEBHOOKS',
  DELETE_WEBHOOKS = 'DELETE_WEBHOOKS',
  READ_WEBHOOKS = 'READ_WEBHOOKS',
  CHANGE_BOT_STATUS = 'CHANGE_BOT_STATUS',
  EDIT_BOT_MESSAGE = 'EDIT_BOT_MESSAGE',
  DELETE_BOT_KEYWORD = 'DELETE_BOT_KEYWORD',
  MANAGE_ROLES = 'MANAGE_ROLES',
  GET_BILLING_DETAILS = 'GET_BILLING_DETAILS',
  UPDATE_BILLING_DETAILS = 'UPDATE_BILLING_DETAILS',
  GET_WALLET_INVOICES = 'GET_WALLET_INVOICES',
  DOWNLOAD_WALLET_INVOICES = 'DOWNLOAD_WALLET_INVOICES',
  GET_SUBSCRIPTION_INVOICES = 'GET_SUBSCRIPTION_INVOICES',
  DOWNLOAD_SUBSCRIPTION_INVOICES = 'DOWNLOAD_SUBSCRIPTION_INVOICES',
  GET_WABA_USAGE_SUMMARY = 'GET_WABA_USAGE_SUMMARY',
  DOWNLOAD_WABA_USAGE_SUMMARY = 'DOWNLOAD_WABA_USAGE_SUMMARY',
  GET_ANALYTICS_WIDGETS = 'GET_ANALYTICS_WIDGETS',
  WRITE_ANALYTICS_WIDGETS = 'WRITE_ANALYTICS_WIDGETS',
  DELETE_ANALYTICS_WIDGETS = 'DELETE_ANALYTICS_WIDGETS',
  READ_WABA_INTEGRATIONS = 'READ_WABA_INTEGRATIONS',
  WRITE_WABA_INTEGRATIONS = 'WRITE_WABA_INTEGRATIONS',
  READ_TEAM_ASSIGNED_CONVERSATIONS = 'READ_TEAM_ASSIGNED_CONVERSATIONS',
}

export interface Permission {
  hasAccess: boolean;
  resources: string[];
}

export interface AllUserPermissions {
  canReadConversation: Permission;
  canWriteConversation: Permission;
  canReadMembers: Permission;
  canAddMembers: Permission;
  canRemoveMembers: Permission;
  canUpdateMembers: Permission;
  canAddIntegration: Permission;
  canConnectIntegration: Permission;
  canDisconnectIntegration: Permission;
  canReadIntegration: Permission;
  canReadAssignedConversation: Permission;
  canWriteAssignedConversation: Permission;
  canReadUnassignedConversation: Permission;
  canWriteUnassignedConversation: Permission;
  canGetCustomers: Permission;
  canManageWallet: Permission;
  canManageChannelAccess: Permission;
  canManageCreatedChannelAccess: Permission;
  channelAccessLevelFullAccess: Permission;
  channelAccessLevelSendOnlyAccess: Permission;
  channelAccessLevelNoAccess: Permission;
  canReadCustomerPhone: Permission;
  canAddWebhooks: Permission;
  canUpdateWebhooks: Permission;
  canDeleteWebhooks: Permission;
  canReadWebhooks: Permission;
  canChangeBotStatus: Permission;
  canEditBotMessage: Permission;
  canDeleteBotKeyword: Permission;
  canManageRoles: Permission;
  canGetBillingDetails: Permission;
  canUpdateBillingDetails: Permission;
  canGetWalletInvoices: Permission;
  canDownloadWalletInvoices: Permission;
  canGetSubscriptionInvoices: Permission;
  canDownloadSubscriptionInvoices: Permission;
  canGetWabaUsageInvoices: Permission;
  canDownloadWabaUsageInvoices: Permission;
  canGetAnalyticsWidgets: Permission;
  canWriteAnalyticsWidgets: Permission;
  canDeleteAnalyticsWidgets: Permission;
  canReadWabaIntegrations: Permission;
  canWriteWabaIntegrations: Permission;
}
