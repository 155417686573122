import { Alert, AlertTitle, Slide } from '@mui/material';
import { useAtom } from 'jotai';
import { FC, useRef, useMemo, useEffect, useCallback } from 'react';
import { notificationAtomFamily } from '../../states';
import useNotifications from '../../hooks';

const Notification: FC<{ id: string }> = ({ id }) => {
  const mountRef = useRef(false);

  const notificationAtom = useMemo(() => {
    return notificationAtomFamily({ id });
  }, [id]);

  const [notification, setNotification] = useAtom(notificationAtom);
  const {
    open,
    message,
    autoHideDuration = 2000,
    type = 'success',
    title,
    showCloseBtn,
  } = notification;

  const { hideNotification, removeNotification } = useNotifications();

  const onExitAnimation = useCallback(() => {
    return removeNotification({ id });
  }, [id, removeNotification]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const wasMounted = mountRef.current;
    if (!wasMounted) {
      timer = setTimeout(function () {
        hideNotification({ id });
      }, autoHideDuration);
      mountRef.current = true;
    }

    return () => {
      if (timer && wasMounted) {
        clearTimeout(timer);
      }
    };
  }, [hideNotification, id, autoHideDuration]);

  return (
    <Slide
      direction="left"
      in={open}
      mountOnEnter
      unmountOnExit
      onExited={onExitAnimation}
    >
      {showCloseBtn ? (
        <Alert
          severity={type}
          onClose={() => setNotification((prev) => ({ ...prev, open: false }))}
        >
          {typeof title === 'string' && title.length > 0 && (
            <AlertTitle>{title}</AlertTitle>
          )}
          {message}
        </Alert>
      ) : (
        <Alert severity={type}>
          {typeof title === 'string' && title.length > 0 && (
            <AlertTitle>{title}</AlertTitle>
          )}
          {message}
        </Alert>
      )}
    </Slide>
  );
};

export default Notification;
