import platform from '../platform';

let token = '';

export async function getFirebaseToken() {
  return token;
}

export async function getFirebaseTokenFromRedirectUrl() {
  return new Promise<string>((resolve) => {
    resolve(new URLSearchParams(window.location.search).get('redirectToken') as string);
  });
}

export async function getFirebaseTokenFromPlatform() {
  if (new URLSearchParams(window.location.search).has('redirectToken')) {
    token = await getFirebaseTokenFromRedirectUrl();
    return;
  }
  token = await platform.getToken();
}
