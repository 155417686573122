import { createRequestAtom } from '../../../../shared/utils/request-atom';
import { UserAndCompanyDetails } from '../typings';
import { atom } from 'jotai';

export const userCompanyAtom = createRequestAtom<UserAndCompanyDetails>({});

export const companyCurrencyAtom = atom((get) => {
  const { currencySymbol, currencyAlignment = 'left' } =
    get(userCompanyAtom).data || {};
  return { currencySymbol, currencyAlignment };
});
