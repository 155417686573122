import { getI18N } from '../../i18N';

interface PolyfillReducerState {
  done: boolean;
  loading: boolean;
  error?: string;
}

enum PolyfillReducerActionTypes {
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  DONE = 'DONE',
}

const initialPolyfillState = {
  loading: true,
  done: false,
  error: undefined,
};

const reducer = (
  prev: PolyfillReducerState,
  action: PolyfillReducerActionTypes
) => {
  const { t } = getI18N();

  if (action === PolyfillReducerActionTypes.DONE) {
    return {
      done: true,
      loading: false,
      error: undefined,
    };
  }

  if (action === PolyfillReducerActionTypes.ERROR) {
    return {
      done: false,
      loading: false,
      error: t('please_check_your_internet_connection_and_try_again'),
    };
  }

  if (action === PolyfillReducerActionTypes.LOADING) {
    return {
      done: false,
      loading: true,
      error: undefined,
    };
  }

  return prev;
};

export default reducer;
export { initialPolyfillState, PolyfillReducerActionTypes };
