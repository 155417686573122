import { get, patch, post } from '../../../../infra/rest';
import { WabaIntegrationMemberStatus } from '../../../waba-channels/v1/typings';
import { Access, UserProfileData, UserStatusData } from '../typings';

export const getUserDetails = async (): Promise<UserProfileData> => {
  return get<UserProfileData>(`/v1/user`, undefined, true);
};

export const setUserDetails = async (data: { [key: string]: string }): Promise<void> => {
  return patch<void>(
    `/v1/user`,
    {
      body: JSON.stringify(data),
    },
    true
  );
};

export const getUserStatus = async (): Promise<UserStatusData> => {
  return get<UserStatusData>(`/v1/organization/user/availability/status`, undefined, true);
};

export const setUserStatus = async ({ status, statusExpiration }: { status: WabaIntegrationMemberStatus | null; statusExpiration: string | null; }): Promise<UserStatusData> => {
  return post<UserStatusData>(
    `/v1/organization/user/availability/status`,
    {
      body: JSON.stringify({ status, statusExpiration }),
    },
    true
  );
};

export const getOtpForUserNumber = (): Promise<void> => {
  return post<void>('/v1/user/send-verification-otp', {}, true);
};

export const verifyOtpForUserNumber = (otp: string): Promise<void> => {
  return post<void>(
    '/v1/user/verify-otp',
    {
      body: JSON.stringify({ code: otp }),
    },
    true
  );
};

export const getOtpForNewNumber = (phone: string): Promise<void> => {
  return post<void>(
    '/v3/auth/send-sms-otp',
    {
      body: JSON.stringify({ phone }),
    },
    true
  );
};

interface ChangeLoginPhoneNumberResponse {
  token: string;
  access: Access;
}

export const changeLoginPhoneNumber = async ({
  phone,
  code,
}: {
  phone: string;
  code: string;
}): Promise<ChangeLoginPhoneNumberResponse> => {
  return await post<ChangeLoginPhoneNumberResponse>(
    `/v1/user/change-login-phone`,
    {
      body: JSON.stringify({ phone, code }),
    },
    true
  );
};
