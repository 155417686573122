import { debounce, Checkbox, CheckboxProps, Radio, RadioProps, FormControl, FormControlProps, TextField } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { companyCurrencyAtom } from '../../../../modules/commerce/v2/state/user-company';
import QSTypography from '../typography';
import { CURRENCY_ALIGNMENT } from '../../../../modules/commerce/v2/typings';
import { OutlinedTextFieldProps } from '@mui/material/TextField/TextField';
import { parseAndValidateNumber } from '../../../utils/format-number';

export function QSCheckbox({ ...props }: CheckboxProps) {
  return <Checkbox {...props} />;
}

export function QSRadio({ ...props }: RadioProps) {
  return <Radio {...props} />;
}

export function QSFormControl({ ...props }: FormControlProps) {
  return <FormControl {...props} />;
}

export interface NumericFieldI extends OutlinedTextFieldProps {
  onChangeValue: (a: string | null) => void;
}

export function QSNumericField({
  defaultValue,
  value,
  onChangeValue: onChange,
  ...props
}: NumericFieldI) {
  const [valueState, setValueState] = useState(
    value || value === 0 ? value : defaultValue || ''
  );
  const debounceRef = useRef(debounce(onChange, 1000));

  useEffect(() => {
    debounceRef.current = debounce(onChange, 1000);
  }, [onChange]);

  useEffect(() => {
    setValueState(value || '');
  }, [value]);

  const onChangeValue = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const strValue: string = event.target.value;
    const formattedValue = parseAndValidateNumber(strValue, { decimal: true });

    setValueState(formattedValue || '');
    debounceRef.current(formattedValue || '');
  }, []);

  return (
    <TextField
      {...props}
      size={'small'}
      value={valueState}
      InputProps={{ ...(props.InputProps || {}), sx: { padding: '0px' } }}
      onChange={onChangeValue}
    />
  );
}

export function QSPriceInputField({ onChangeValue, ...props }: NumericFieldI) {
  const [currencyState] = useAtom(companyCurrencyAtom);
  const { currencySymbol, currencyAlignment } = currencyState;

  const adornment = currencySymbol ? (
    <QSTypography style={{ padding: '5px' }} fontSize={15}>
      {currencySymbol}
    </QSTypography>
  ) : null;

  const startAdornment =
    currencyAlignment === CURRENCY_ALIGNMENT.left ? adornment : null;
  const endAdornment =
    currencyAlignment === CURRENCY_ALIGNMENT.right ? adornment : null;

  return (
    <QSNumericField
      {...props}
      size={'small'}
      onChangeValue={onChangeValue}
      InputProps={{
        sx: { fontSize: 13 },
        ...(props.InputProps || {}),
        startAdornment,
        endAdornment,
      }}
    />
  );
}
